import { Icon } from "@iconify/react";
import parse from "html-react-parser";

export default function ExportButton({ variant, setOpen }) {
  return (
    <div
      className='button glass shine'
      style={{ margin: "20px" }}
      onClick={() => {
        setOpen(true);
      }}
    >
      <h6>
        {variant === "full"
          ? parse("Enroll Now for <s>₹4002</s> ₹1499")
          : "Enroll Now"}
      </h6>
      <Icon
        icon='bi:arrow-right'
        style={{ scale: "2", marginLeft: "15px", color: "white" }}
      />
    </div>
  );
}
