import Slider from "react-slick";
// import { Icon } from "@iconify/react";
import "./cs.css";
export default function CompaniesSlider({ brands }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    // autoplaySpeed: 0,
    // cssEase: "linear",
    // speed: 20000,
    slidesToShow: 5,
    slidesToScroll: 5,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          speed: 3000,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          speed: 2000,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: 2,
      //     // dots: true,
      //     arrows: false,
      //   },
      // },
    ],
  };
  return (
    <div className='csContainer'>
      <h5>Expertly curated roadmaps by mentors from</h5>
      <Slider {...settings} className=''>
        {brands.map((brand, index) => (
          <div key={index}>
            {/* <h5> {brand}</h5> */}

            <img src={brand} alt={index} className='csImg' />
          </div>
        ))}
      </Slider>
    </div>
  );
}
