import { Icon } from "@iconify/react";

export default function ProblemCard({ prob }) {
  return (
    <div className='problemDiv'>
      <h5>{prob.title}</h5>
      <Icon icon={prob.iconLink} className='problemIcon' />
      <h6>{prob.problem}</h6>
    </div>
  );
}
