import "./mentors.css";
import { Icon } from "@iconify/react";
import { useState } from "react";
import AboutMentorship from "./AboutMentorship";
import ViewMentors from "./ViewMentors";
export default function Mentors() {
  const [selected, setSelected] = useState("about");
  return (
    <div className='ccContainer'>
      <div className='ccTitle'>
        <img src='images/small_elements/2.png' alt='' />
        <h4>Expert Mentorship</h4>
      </div>
      <div className='ccArrowDiv'>
        <h1 className='ccH1'> 150+ </h1>
        <h5 className='ccH5'>Finance Mentors</h5>
      </div>
      <div className='mentorsDetails'>
        <div className='selectBtn'>
          <div
            className={`button  ${selected === "about" ? "selectedBtn" : ""}`}
            onClick={() => setSelected("about")}
            style={{
              width: "150px",
              height: "30px",
              padding: "5px",
              color: "white",
            }}
          >
            About Mentorship
          </div>
          <div
            className={`button  ${selected === "view" ? "selectedBtn" : ""}`}
            style={{
              width: "150px",
              height: "30px",
              padding: "5px",
              color: "white",
            }}
            onClick={() => setSelected("view")}
          >
            View Mentors
          </div>
        </div>
        {selected === "about" ? <AboutMentorship /> : <ViewMentors />}
      </div>
    </div>
  );
}
