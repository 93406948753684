import { useState } from "react";
import "./accordian.css";
export default function Accordion({ accordionData }) {
  const [selected, setSelected] = useState();
  const handelToggle = (index) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };
  return (
    <div className='cs-accordians cs-style1'>
      {accordionData.map((item, index) => (
        <div
          className={`cs-accordian ${selected === index ? "active" : ""}`}
          key={index}
        >
          <div
            className='cs-accordian_head'
            onClick={() => handelToggle(index)}
          >
            <h2 className='cs-accordian_title'>{item.question}</h2>
            {/* <span className='cs-accordian_toggle cs-accent_color'>
              <svg
                width={15}
                height={8}
                viewBox='0 0 15 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M0 0L7.5 7.5L15 0H0Z' fill='currentColor' />
              </svg>
            </span> */}
          </div>
          <div className='cs-accordian_body'>
            <div className='cs-accordian_body_in'>{item.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
