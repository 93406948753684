export default function AboutMentorship() {
  return (
    <div className='abtMentors'>
      <div className='abtTitle'>
        <h5>950+ Jobs </h5>
      </div>

      <h6>Placements facilitated across various roles.</h6>
      <div className='abtTitle'>
        <h5>7,300+ Hours </h5>
      </div>

      <h6>
        Personalized mentorship delivered by our industry-leading experts.
      </h6>
      <div className='abtTitle'>
        <h5>2,340+ Mentees </h5>
      </div>

      <h6>Achieved an career growth through our Mentorship Program.</h6>
      <div className='abtTitle'>
        <h5>3,000+ Finance Professionals </h5>
      </div>

      <h6>Are the part of our exclusive network accessible by our mentees.</h6>
    </div>
  );
}
