import Slider from "react-slick";
import { Icon } from "@iconify/react";

export default function RoadmapScroll() {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden='true'
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon='bi:arrow-left' />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden='true'
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon='bi:arrow-right' />
    </div>
  );
  const roadmapData = [
    {
      title: "Investment Banking",
      logo: [
        { url: "images/companies_logo/1.png" },
        { url: "images/companies_logo/2.png" },
        { url: "images/companies_logo/3.png" },
        { url: "images/companies_logo/4.png" },
      ],
      ul: [
        { li: "1000+ Invesment Banks in India" },
        {
          li: " Handles over $1 trillion in mergers and acquisitions annually.",
        },
        {
          li: 'Investment bankers are known for their grueling "work hard, playhard" culture.',
        },
        { li: "Average CTC for Investment Bankers in India ₹10,00,000." },
      ],
    },
    {
      title: "Management Consulting",
      logo: [
        { url: "images/companies_logo/5.png" },
        { url: "images/companies_logo/6.png" },
        { url: "images/companies_logo/7.png" },
        { url: "images/companies_logo/8.png" },
      ],
      ul: [
        { li: "Consulting firms are the CEO - Factory of the world." },
        {
          li: "Management consulting services account for $300 billion in annual revenue.",
        },
        {
          li: "The Big 4 consulting firms (Deloitte, PwC, EY, KPMG) employ over 1 million consultants worldwide.",
        },
      ],
    },
    {
      title: "Equity Research",
      logo: [
        { url: "images/companies_logo/9.png" },
        { url: "images/companies_logo/10.png" },
        { url: "images/companies_logo/11.png" },
        { url: "images/companies_logo/12.png" },
      ],
      ul: [
        { li: "Over 35,000 equity research analysts worldwide." },
        {
          li: `Known as "Wall Street's Detectives" for their investigative approach to analyzing companies.`,
        },
        {
          li: "Analysts are known for their love of financial modeling and spreadsheets.",
        },
      ],
    },
    {
      title: "Credit Management",
      logo: [
        { url: "images/companies_logo/13.png" },
        { url: "images/companies_logo/14.png" },
        { url: "images/companies_logo/15.png" },
        { url: "images/companies_logo/16.png" },
      ],
      ul: [
        {
          li: "The global credit card industry generates over $180 billion in annual revenue.",
        },
        {
          li: `Professionals often refer to themselves as "Credit Ninjas"`,
        },
        {
          li: "The credit management industry employs over 500,000 professionals.",
        },
      ],
    },
    {
      title: "Wealth Management",
      logo: [
        { url: "images/companies_logo/17.png" },
        { url: "images/companies_logo/18.png" },
        { url: "images/companies_logo/19.png" },
        { url: "images/companies_logo/20.png" },
      ],
      ul: [
        {
          li: `The term "High Net Worth Individual" refers to individuals with assets of over $1Mn.`,
        },
        {
          li: `The top 10 wealth management firms manage over $25 trillion in assets.`,
        },
        {
          li: "India ranked fifth on the list of countries with the most Ultra HNI worldwide.",
        },
      ],
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };
  return (
    <Slider {...settings} className='rdSlides'>
      {roadmapData.map((data, index) => (
        <div className='roadmapDetails' key={index}>
          <div className='ccRoadmapTitle'>
            <h5>{data.title}</h5>
          </div>
          <div className='ccList'>
            <ul>
              {/* test {data.ul[1].li} */}
              {data.ul.map((item, idx) => (
                <li key={idx}>{item.li}</li>
              ))}
            </ul>
          </div>
          <div className='ccLogo'>
            {data.logo.map((imgSrc, idx2) =>
              imgSrc.url === "images/companies_logo/1.png" ? (
                <img
                  src={imgSrc.url}
                  alt=''
                  style={{ scale: "1.14" }}
                  key={idx2}
                />
              ) : imgSrc.url === "images/companies_logo/10.png" ? (
                <img
                  src={imgSrc.url}
                  alt=''
                  style={{ marginTop: "5px" }}
                  key={idx2}
                />
              ) : (
                <img src={imgSrc.url} alt='' key={idx2} />
              )
            )}
          </div>
        </div>
      ))}
    </Slider>
  );
}
