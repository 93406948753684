import "./skill.css";
export default function SkillDev() {
  return (
    <div className='ccContainer'>
      <div className='ccTitle'>
        <img src='images/small_elements/3.png' alt='' />
        <h4>Skill Developement</h4>
      </div>
      <div className='ccArrowDiv'>
        <h1 className='ccH1'> 1000+ </h1>
        <h5 className='ccH5'>Resources</h5>
      </div>
      <div className='aboutSkillDev'>
        <div className='skillDetails'>
          <div
            className='skillImg'
            style={{
              backgroundImage: `url('images/skill_dev/1.png')`,
              backgroundSize: "140%",
            }}
          ></div>
          <h5 className='h5Right'>
            <span className='skillSpan'> 200+ </span>video courses covering
            various finance topics
          </h5>
        </div>
        <div className='skillDetails'>
          <h5 className='h5Right'>
            <span className='skillSpan'> 300+ </span>downloadable templates,
            cheatsheets, & quick reference guides
          </h5>{" "}
          <div
            className='skillImg'
            style={{
              backgroundImage: `url('images/skill_dev/2.png')`,
              backgroundSize: "150%",
            }}
          ></div>
        </div>
        <div className='skillDetails'>
          <div
            className='skillImg'
            style={{
              backgroundImage: `url('images/skill_dev/3.png')`,
              backgroundSize: "85%",
            }}
          ></div>
          <h5 className='h5Right'>
            <span className='skillSpan'> 5,00+ </span> practice questions and
            quizzes{" "}
          </h5>
        </div>
        <div className='skillDetails'>
          <h5 className='h5Right'>
            <span className='skillSpan'> 150+ </span> case studies and practice
            projects
          </h5>{" "}
          <div
            className='skillImg'
            style={{
              backgroundImage: `url('images/skill_dev/4.png')`,
              backgroundSize: "115%",
            }}
          ></div>
        </div>
        <div className='skillDetails'>
          <div
            className='skillImg'
            style={{
              backgroundImage: `url('images/skill_dev/5.png')`,
              backgroundSize: "150%",
            }}
          ></div>
          <h5 className='h5Right'>
            <span className='skillSpan'> 100+ </span>hours of recorded webinars
            & expert sessions
          </h5>
        </div>
      </div>
    </div>
  );
}
