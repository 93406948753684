import "./testimonial.css";

export default function TestimonialStyle2({ data }) {
  return (
    <div className='testMain'>
      <div className='testLeft'>
        <div className='testImgContainer'>
          <img src={data.testimonialThumb} alt='Avatar' className='testImg' />
        </div>
        <div className='cs-rating'>
          <div
            className='cs-rating_bg'
            style={{ backgroundImage: "url(/images/rating.svg)" }}
          />
          <div
            className='cs-rating_percentage'
            style={{
              backgroundImage: "url(/images/rating.svg)",
              width: `${data.ratings * 20}%`,
            }}
          />
        </div>
      </div>
      <div className='testRight'>
        <h6>{data.testimonialText}</h6>
        <div className='testName'>
          <h5>-{data.avatarName}</h5>
          <h6>{data.avatarDesignation}</h6>
        </div>
      </div>
    </div>
  );
}
