import "./hero.css";
import ReactRotatingText from "react-rotating-text";

import RoadmapOffer from "../RoadmapOffer/RoadmapOffer";
export default function Hero({ setOpen }) {
  return (
    <div className='heroMain cs-shape_wrap_1'>
      <div className='cs-shape_1' />
      <div className='cs-shape_1' />
      <div className='cs-shape_1' />
      <div className='heroContainer'>
        <div className='heroLeft'>
          <div className='heroText'>
            {" "}
            <h2 className='cs-hero_title'>Roadmap to your: </h2>
            <h2 className='cs-hero_title'>
              <ReactRotatingText
                items={["CAREER", "GROWTH", "PASSION", "SUCCESS"]}
                color='#4bc9ce'
              />
            </h2>
            {/* <div className='heroSubtitle'>
              <h5>
                Encoding career is a place to clarify your career, gain
                real-world skills and get 1-on-1 mentorship to excel in your
                chosen field.
              </h5>
            </div> */}
          </div>
        </div>
        <div className='heroRight'>
          <RoadmapOffer setOpen={setOpen} />
        </div>
      </div>
    </div>
  );
}
