import { Icon } from "@iconify/react";
export default function ModuleCard({ data }) {
  return (
    <div className={"moduleDiv " + data.class}>
      <div className='rdChip'>
        <h6>{data.module}</h6>
      </div>
      <h4>{data.title}</h4>
      <p>
        <li style={{ marginLeft: "15px" }}>{data.description}</li>
      </p>

      {data.points.map((point, index) => (
        <div className='moduleDetail' key={index}>
          <Icon icon='mdi:tick-circle' className='moduleIcon' />
          <h6>{point}</h6>
        </div>
      ))}
    </div>
  );
}
