import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../pages/components/Header/Header";
import Footer from "../pages/components/Footer/Footer";
export default function Layout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* <Spacing lg='60' md='60' /> */}
      <Outlet />
      <Footer />
    </>
  );
}
