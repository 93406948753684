import ExportButton from "../Elements/EnrollButton";
import BonusCard from "./BonusCard";
import { Icon } from "@iconify/react";
import "./bm.css";
// import "../RoadmapDetails/rd.css";
const bonusData = [
  {
    module: "<s >₹7999</s> Free",
    title: "Practical Project Work",
    class: "sticky1",
    description:
      "Put your knowledge into practice with our hands-on project work curated by industry experts.",
    points: [
      "Real-world project briefs and requirements",
      "Step-by-step project guides and tutorials",
      "Sample datasets and project files",
    ],
  },

  {
    module: "<s >₹4999</s> Free",
    title: "Interview Preparation",
    class: "sticky2",

    description:
      "Increase your confidence and preparedness for job interviews with interview preparation resources.",
    points: [
      "Common interview questions and sample answers",
      "Role-specific interview tips and strategies",
      "Negotiation tactics & Post-interview follow-up best practices",
    ],
  },
  {
    module: "<s >₹4999</s> Free",
    title: "Skill Development Resources",
    class: "sticky3",

    description:
      "Boost your learning journey with our curated skill development resources.",
    points: [
      "Industry-tailored notes and study materials",
      "Curated selection of relevant books/ebooks & videos.",
      "Integration for comprehensive online courses and certifications",
    ],
  },
  {
    module: "<s >₹1999</s> Free",
    title: "Like-Minded Community",
    class: "sticky1",

    description:
      "Join our vibrant community of like-minded individuals on similar career journeys.",
    points: [
      "User-generated content and knowledge sharing",
      "Get your doubts resolved by the industry experts",
      "Industry news updates, career advice & mentorship opportunities",
    ],
  },
];
export default function BonusMaterial({ setOpen }) {
  return (
    <div className='rdContainer'>
      <div className='stickyWrap1'>
        <div className='rdTitle'>
          {/* <h4>Bonus Material</h4> */}
          <h5 className='bdText'>
            Register now to unlock bonuses worth ₹19,999
          </h5>
          <div
            className='button glass bmButton shine'
            style={{ marginTop: "20px" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <h6>Enroll Now</h6>
            <Icon
              icon='bi:arrow-right'
              style={{ scale: "2", marginLeft: "15px", color: "white" }}
            />
          </div>
        </div>{" "}
      </div>
      {/* <h4>Bonus Material</h4>
      <h5>Register now to unlock bonuses worth ₹19,999</h5>
      <div style={{ width: "200px", margin: "auto" }}>
        <ExportButton />
      </div> */}
      <div className='stickyWrap'>
        <div className='rdTitle2'>
          <h5 className='bdText'>
            Register now to unlock bonuses worth ₹19,999
          </h5>
        </div>{" "}
        {bonusData.map((data, index) => (
          <BonusCard key={index} data={data} />
        ))}
      </div>
      <div
        className='button glass bmButton mobButton shine'
        style={{ margin: "0px 20px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <h6>Enroll Now</h6>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div>
    </div>
  );
}

{
  /* <h4>Bonus Material</h4>
<h5>Register now to unlock bonuses worth ₹19,999</h5> */
}
