import ModuleCard from "../Elements/ModuleCard";
import "./rd.css";
import { Icon } from "@iconify/react";

const moduleData = [
  {
    module: "Module 1",
    title: "Industry Overview",
    class: "sticky1",
    description:
      "Comprehensive understanding of the industry you're aspiring to be a part of.",
    points: [
      "Industry definition, scope, and key sectors",
      "Major players, market trends, and growth projections",
      "Success stories and case studies of industry leaders",
    ],
  },

  {
    module: "Module 2",
    title: "Roles & Salaries",
    class: "sticky2",

    description:
      "Explore the various job roles, and potential earning opportunities within the industry.",
    points: [
      "Detailed job descriptions and responsibilities",
      "Salary breakdowns by experience level, location, and company size",
      "Non-monetary benefits and perks offered in the industry",
    ],
  },
  {
    module: "Module 3",
    title: "Education & Skills",
    class: "sticky3",

    description:
      "Comprehensive understanding of the industry you're aspiring to be a part of.",
    points: [
      "Educational qualifications and certifications required",
      "Technical and soft skills necessary for success",
      "Resources for skill development (courses, workshops, notes etc.)",
    ],
  },
  {
    module: "Module 4",
    title: "Benefits & Challenges",
    class: "sticky4",

    description:
      "Realistic understanding of the potential benefits and challenges you may encounter in your career.",
    points: [
      "Work-life balance strategies and self-care practices",
      "Work environments and culture in the industry",
      "Common career progression challenges and how to overcome them",
    ],
  },
  {
    module: "Module 5",
    title: "Mentorship",
    class: "sticky5",

    description:
      "Leverage the power of mentorship to accelerate your career growth.",
    points: [
      "Importance of mentorship and its benefits",
      "Major players, market trends, and growth projections",
      "Step-by-step guide for finding and approaching potential mentors",
    ],
  },
  {
    module: "Module 6",
    title: "Professional Destinations",
    class: "sticky6",

    description:
      "Explore the various job roles, and potential earning opportunities within the industry.",
    points: [
      "Company profiles, culture, and work environments",
      "Tips for researching and applying to these companies",
      "List of top companies and profiles",
    ],
  },
  {
    module: "Module 7",
    title: "Career Horizon",
    class: "sticky7",

    description:
      "Exploring emerging technologies, future job roles, & strategies for continuous learning and adaptability.",
    points: [
      "Emerging technologies and their impact",
      "Future job roles for transition and skill requirements",
      "Strategies for staying relevant and adaptable",
    ],
  },
];
export default function RoadmapDetails({ setOpen }) {
  return (
    <div className='rdContainer'>
      <div className='stickyWrap1'>
        <div className='rdTitle'>
          <h5 className='rdText'>
            That was just the beginning, you will also learn:
          </h5>
          <div
            className='button glass rdButton shine'
            style={{ marginTop: "20px" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <h6>Enroll Now</h6>
            <Icon
              icon='bi:arrow-right'
              style={{ scale: "2", marginLeft: "15px", color: "white" }}
            />
          </div>
        </div>{" "}
      </div>

      <div className='stickyWrap'>
        <div className='rdTitle2'>
          <h5 className='rdText'>
            That was just the beginning, you will also learn:
          </h5>
        </div>
        {moduleData.map((data, index) => (
          <ModuleCard key={index} data={data} />
        ))}
      </div>
      <div
        className='button glass rdButton mobButton shine'
        style={{ margin: "0px 20px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <h6>Enroll Now</h6>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div>
    </div>
  );
}
