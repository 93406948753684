import { Icon } from "@iconify/react";
import "./pricing.css";
export default function Pricing({ setOpen }) {
  const pricingData = [
    {
      icon: "iconamoon:folder-video-fill",
      text: "Recorded content for all modules",
    },
    {
      icon: "majesticons:presentation-play-line",
      text: "Access to all webinars by mentors",
    },
    {
      icon: "fluent:people-community-add-28-filled",
      text: "Access to the mentors’ community",
    },
    {
      icon: "la:question",
      text: "Q&A with all the mentors",
    },
    {
      icon: "el:gift",
      text: "Bonuses worth ₹19,999 ",
    },

    {
      icon: "fluent-mdl2:text-document-edit",
      text: "Module notes",
    },
    {
      icon: "tabler:certificate",
      text: "Certification",
    },
  ];
  return (
    <div className='pricingContainer'>
      <h3>Pricing</h3>
      <div className='pricingMain'>
        <div className='bonusDiv pricingDiv'>
          <h6>
            <s>₹4002</s> ₹1499
          </h6>
        </div>
        {pricingData.map((data, index) => (
          <div style={{ width: "100%" }} key={index}>
            <div className='pricingDetails'>
              <Icon icon={data.icon} className='pricingIcon' />
              <h6>{data.text} </h6>
            </div>
            {index !== pricingData.length - 1 && (
              <hr className='hrText' data-content='+' />
            )}

            {/* <hr
              style={{
                width: "90%",
                borderTop: "1px solid #bbb",
                margin: "auto",
              }}
            /> */}
          </div>
        ))}
        <div style={{ width: "200px", margin: "auto", marginBottom: "20px" }}>
          <div
            className='button glass bmButton shine'
            style={{ margin: "0px 20px" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <h6>Enroll Now</h6>
            <Icon
              icon='bi:arrow-right'
              style={{ scale: "2", marginLeft: "15px", color: "white" }}
            />
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
