import { useState } from "react";
import Modal from "@mui/material/Modal";

import EnrollModal from "../Model/EnrollModel";
import "./ef.css";
export default function EnrollFooter() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        padding: "10px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6>
          <s>₹4002</s> ₹1499
        </h6>
        {/* <Countdown
              // date={Date.now() + 10000}
              date={1715777859000}
              intervalDelay={0}
              renderer={renderer}
            /> */}
      </div>
      <button
        className='enrollBtn glass'
        onClick={() => {
          setOpen(true);
        }}
      >
        <span style={{ color: "white" }}>Enroll Now</span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ backdropFilter: "blur(8px)" }}
      >
        <div className='enrollModule'>
          <EnrollModal handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
}
