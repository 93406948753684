const mentorsData = [
  {
    name: "Pranav Desai",
    position: "- Project Leader",
    company: "Bain & Company",
    imgSrc: "images/mentors_photo/1.png",
  },
  {
    name: "Anjali Gupta",
    position: "Investment Banking Associate",
    company: "Kotak Mahindra Capital",
    imgSrc: "images/mentors_photo/11.png",
  },
  {
    name: "Arjun Mehta",
    position: "- Manager, Capital Markets",
    company: "Axis Capital",
    imgSrc: "images/mentors_photo/2.png",
  },
  {
    name: "Nisha Malhotra",
    position: "Senior Associate",
    company: "M&A Advisory, JM Financial",
    imgSrc: "images/mentors_photo/12.png",
  },
  {
    name: "Rohan Reddy",
    position: "- Senior Research Analyst",
    company: "Motilal Oswal",
    imgSrc: "images/mentors_photo/3.png",
  },
  {
    name: "Ritika Kashyap",
    position: "Manager",
    company: "Boston Consulting Group",
    imgSrc: "images/mentors_photo/13.png",
  },
  {
    name: "Aditya Singh",
    position: "- Engagement Manager",
    company: "Mckinsey & Company",
    imgSrc: "images/mentors_photo/4.png",
  },
  {
    name: "Priya Kapoor",
    position: "Senior Consultant",
    company: "Deloitte",
    imgSrc: "images/mentors_photo/14.png",
  },
  {
    name: "Aryan Chawla",
    position: "- Research Associate",
    company: "JM Financial",
    imgSrc: "images/mentors_photo/5.png",
  },
  {
    name: "Ishita Srinivasan",
    position: "Associate Manager",
    company: "Edelweiss Research",
    imgSrc: "images/mentors_photo/15.png",
  },
  {
    name: "Rohit Sharma",
    position: "- Associate Vice President",
    company: "ICICI Securities",
    imgSrc: "images/mentors_photo/6.png",
  },
  {
    name: "Diya Narayanan",
    position: "Equity Research Analyst",
    company: "Kotak Securities",
    imgSrc: "images/mentors_photo/16.png",
  },
  {
    name: "Karan Raj",
    position: "- Credit Risk Manager",
    company: "HDFC Bank",
    imgSrc: "images/mentors_photo/7.png",
  },
  {
    name: "Neha Ranjan",
    position: "Asistant Vice President",
    company: "SBI Cards",
    imgSrc: "images/mentors_photo/17.png",
  },
  {
    name: "Shikhar Chopra",
    position: "- Manager, Risk Analytics",
    company: "ICICI Bank",
    imgSrc: "images/mentors_photo/8.png",
  },
  {
    name: "Meera Ahuja",
    position: "Senior Credit Analyst",
    company: "Axis Bank",
    imgSrc: "images/mentors_photo/18.png",
  },
  {
    name: "Aarav Raghavan",
    position: "- Relationship Manager",
    company: "IIFL Wealth",
    imgSrc: "images/mentors_photo/9.png",
  },
  {
    name: "Ananya Kulkarni",
    position: "Portfolio Advisor",
    company: "Avendus Wealth",
    imgSrc: "images/mentors_photo/19.png",
  },
  {
    name: "Sandeep Patil",
    position: "- Investment Counsellor",
    company: "Kotak Wealth",
    imgSrc: "images/mentors_photo/10.png",
  },
  {
    name: "ATrisha Subramaniam",
    position: "Wealth Planning Manager",
    company: "BNP Paribas",
    imgSrc: "images/mentors_photo/20.png",
  },
];

export default mentorsData;
