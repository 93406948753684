import React from "react";

export default function GiftIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      viewBox='0 0 48 48'
      {...props}
    >
      <path
        fill='black'
        d='M5.06 44.5a14.21 1.5 0 1 0 28.42 0a14.21 1.5 0 1 0-28.42 0'
        opacity={0.15}
      ></path>
      <path
        fill='white'
        d='m19.11 30.76l-13-6.39L4.52 37.3a.76.76 0 0 0 .43.78l12.55 6.16a3.1 3.1 0 0 0 2 .24l13.65-3a.79.79 0 0 0 .6-.67l1.55-12.93L21.15 31a3.12 3.12 0 0 1-2.04-.24'
      ></path>
      <path
        fill='#2d8184'
        d='M21.15 31a3.12 3.12 0 0 1-2-.25l-13-6.39l-.55 3.86l13.05 6.39a3.1 3.1 0 0 0 2 .25l14.19-3.14l.46-3.85Z'
      ></path>
      <path
        fill='none'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m19.11 30.76l-13-6.39L4.52 37.3a.76.76 0 0 0 .43.78l12.55 6.16a3.1 3.1 0 0 0 2 .24l13.65-3a.79.79 0 0 0 .6-.67l1.55-12.93L21.15 31a3.12 3.12 0 0 1-2.04-.24'
      ></path>
      <path
        fill='none'
        stroke='#a1d6d8'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m18.66 43.18l1.24-10.4'
      ></path>
      <path
        fill='#a1d6d8'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m35.33 27.87l-13-6.4a3.12 3.12 0 0 0-2-.24L6.06 24.37l13.05 6.39a3.12 3.12 0 0 0 2 .25Z'
      ></path>
      <path fill='white' d='M9.02 25.81L7.4 39.29l6.64 3.26l1.62-13.48z'></path>
      <path fill='#a1d6d8' d='m9.02 25.81l-.47 3.85l6.65 3.26l.46-3.85z'></path>
      <path
        fill='none'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9.02 25.81L7.4 39.29l6.64 3.26l1.62-13.48z'
      ></path>
      <path
        fill='white'
        d='m32.12 28.58l-1.61 13.48l-7.22 1.59l1.61-13.47z'
      ></path>
      <path
        fill='#a1d6d8'
        d='m32.12 28.58l-.46 3.85l-7.22 1.6l.46-3.85z'
      ></path>
      <path
        fill='none'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m32.12 28.58l-1.61 13.48l-7.22 1.59l1.61-13.47z'
      ></path>
      <path
        fill='white'
        d='m16.53 18.93l-15.3-1.24l1.24 4.72a.76.76 0 0 0 .69.57l14.75 1.2a3.09 3.09 0 0 0 2-.52l12.26-8.26a.8.8 0 0 0 .32-.84l-1.24-4.72l-12.73 8.57a3.18 3.18 0 0 1-1.99.52'
      ></path>
      <path
        fill='white'
        d='M18.52 18.41a3.18 3.18 0 0 1-2 .52L1.23 17.69l.59 2.25l15.3 1.24a3.19 3.19 0 0 0 2-.52l12.73-8.57l-.59-2.25Z'
      ></path>
      <path
        fill='none'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m16.53 18.93l-15.3-1.24l1.24 4.72a.76.76 0 0 0 .69.57l14.75 1.2a3.09 3.09 0 0 0 2-.52l12.26-8.26a.8.8 0 0 0 .32-.84l-1.24-4.72l-12.73 8.57a3.18 3.18 0 0 1-1.99.52'
      ></path>
      <path
        fill='white'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M1.23 17.69L14 9.11a3.08 3.08 0 0 1 2-.51l15.3 1.24l-12.78 8.57a3.18 3.18 0 0 1-2 .52Z'
      ></path>
      <path
        fill='white'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M18.28 8.78L4.51 17.95l1.37 5.26l7.38.59l-1.38-5.25l13.77-9.17z'
      ></path>
      <path
        fill='none'
        stroke='#2d8184'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m6.7 18.13l3.72.3'
      ></path>
      <path
        fill='white'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m12.03 10.42l16.49 1.25l1.38 5.25l-6.14 4.13l-1.37-5.25L5.9 14.55z'
      ></path>
      <path
        fill='none'
        stroke='#2d8184'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m26.7 12.9l-3.09 2.08'
      ></path>
      <path
        fill='#a1d6d8'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m18.9 13.6l-1.84-1.31s2-8 7.46-8.66A2.3 2.3 0 0 1 27 5.15l.86 2.35Z'
      ></path>
      <path
        fill='#2d8184'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M18.9 13.6s4.74-.37 8.81-5.28A.78.78 0 0 0 27 7a10.27 10.27 0 0 0-8.1 6.6'
      ></path>
      <path
        fill='#a1d6d8'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m14 15l.81-2.11S9 7.56 4.05 10a2.31 2.31 0 0 0-1.19 2.62l.59 2.44Z'
      ></path>
      <path
        fill='#2d8184'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M14 15s-3.9 2.51-10 .68a.78.78 0 0 1-.13-1.45A9.91 9.91 0 0 1 14 15'
      ></path>
      <path
        fill='#2d8184'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M14.5 14.3s-5.1 3-5.36 7.29l2.6-1.1l1.49 2.56s1.12-6.78 3.29-8.22Zm3.56-.88s5.9.25 8.14 4l-2.81.24l-.13 2.95s-4.15-5.48-6.74-5.75Z'
      ></path>
      <path
        fill='#2d8184'
        d='M19.41 12.07c.38 1.45-.83 3-2.69 3.51s-3.69-.29-4.06-1.74s.82-3 2.69-3.51s3.65.29 4.06 1.74'
      ></path>
      <path
        fill='#a1d6d8'
        d='M15.74 11.83a3.46 3.46 0 0 1 3.71 1a2.16 2.16 0 0 0 0-.8c-.38-1.45-2.2-2.23-4.06-1.74s-3.07 2.06-2.69 3.51a2 2 0 0 0 .35.71a3.47 3.47 0 0 1 2.69-2.68'
      ></path>
      <path
        fill='none'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M19.41 12.07c.38 1.45-.83 3-2.69 3.51s-3.69-.29-4.06-1.74s.82-3 2.69-3.51s3.65.29 4.06 1.74'
      ></path>
      <path
        fill='#2d8184'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M29.33 26.48a4.66 4.66 0 0 1-3.83 2.17l-1.36-2.39A4.67 4.67 0 0 0 28 24.08Z'
      ></path>
      <path
        fill='#2d8184'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M45 9.18a4.69 4.69 0 0 1-2 4l-2.47-1.21a4.69 4.69 0 0 0 1.94-4Z'
      ></path>
      <path
        fill='#a1d6d8'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M39.55 47a4.68 4.68 0 0 1-.93-4.31l2.7-.58a4.7 4.7 0 0 0 .92 4.31Z'
      ></path>
      <path
        fill='#4bc9ce'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M47.5 25.53a4.68 4.68 0 0 1-4.23 1.24l-.78-2.64a4.68 4.68 0 0 0 4.23-1.25Z'
      ></path>
      <path
        fill='#4aeff7'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M36.54 32.53a4.7 4.7 0 0 1 3.81 2.23L39 37.14a4.66 4.66 0 0 0-3.8-2.23Z'
      ></path>
      <path
        fill='#4bc9ce'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M30 44.7a4.7 4.7 0 0 1-4.18-1.41l.89-2.61a4.65 4.65 0 0 0 4.17 1.41Zm2.82-23.16a4.7 4.7 0 0 1 2.86-3.36l2.1 1.82a4.68 4.68 0 0 0-2.86 3.36Z'
      ></path>
    </svg>
  );
}
