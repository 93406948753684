import Hero from "./components/Hero/Hero";
import CompaniesSlider from "./components/CompaniesSlider/CompaniesSlider";
import AboutKaran from "./components/AboutKaran/AboutKaran";
import { Icon } from "@iconify/react";
import RoadmapDetails from "./components/RoadmapDetails/RoadmapDetails";
import BonusMaterial from "./components/BonusMaterial/BonusMaterial";
import ProgramQuestions from "./components/ProgramQuestions/ProgramQuestions";
import Pricing from "./components/Pricing/Pricing";
// import WhyEC from "./components/WhyEC/WhyEC";
import Details from "./components/Details/Details";
import Testimonials from "./components/Testimonial/Testimonial";
import AccordianFAQ from "./components/Accordion/AccordionFAQ";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import EnrollModal from "./components/Model/EnrollModel";

const companiesLogo = [
  "/images/brands/morgan.png",
  "/images/brands/goldman.png",
  "/images/brands/avendus.png",
  "/images/brands/jpmorgan.png",
  "/images/brands/citi.png",
  "/images/brands/american.png",
  "/images/brands/barclays.png",
  "/images/brands/hsbc.png",
  "/images/brands/dsp.png",
  "/images/brands/blackrock.png",
  "/images/brands/sbi.png",
  "/images/brands/mirae.png",
  "/images/brands/bcg.png",
  "/images/brands/mckinsey.png",
  "/images/brands/deloitte.png",
  "/images/brands/ey.png",
  "/images/brands/creditsueisse.png",
  "/images/brands/equirus.png",
  "/images/brands/36one.png",
  "/images/brands/ubs.png",
];
export default function Home() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Hero setOpen={setOpen} />
      <div className='' style={{ backgroundColor: "black" }}>
        <div className='companiesDiv' style={{ width: "80%", margin: "auto" }}>
          <CompaniesSlider brands={companiesLogo} />
        </div>
      </div>
      <Details setOpen={setOpen} />
      {/* ////////////confused about carreer start */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "30px 0px 15px 0px",
          backgroundColor: "black",
        }}
      >
        <h5>Confused about career path?</h5>
        <Icon
          icon='line-md:question-square'
          style={{ scale: "4", marginTop: "15px" }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            href='https://quiz.encodingcareers.com'
            className='cs-center'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div
              className='button glass shine'
              style={{ margin: "20px", flexDirection: "column" }}
              //   onClick={() => {
              //     handleClick("name");
              //   }}
            >
              <h5 style={{ fontWeight: "100", fontSize: "16px" }}>
                Take Passion Profile Assessment
              </h5>

              <h3>
                Start Now for <s style={{ color: "red" }}>₹149</s> Free{" "}
              </h3>
            </div>
          </a>
        </div>
      </div>
      {/* ////////////confused about carreer end */}
      <div
        className=''
        style={{ backgroundColor: "black", paddingBottom: "100px" }}
      >
        <RoadmapDetails setOpen={setOpen} />
      </div>
      <div
        className=''
        style={{ backgroundColor: "#2b2929", paddingBottom: "100px" }}
      >
        <BonusMaterial setOpen={setOpen} />
      </div>
      {/* <WhyEC /> */}

      <div>
        <AboutKaran />
      </div>
      <ProgramQuestions setOpen={setOpen} />
      <div
        className=''
        style={{ backgroundColor: "#2b2929", paddingBottom: "100px" }}
      >
        <Pricing setOpen={setOpen} />
      </div>

      <div
        className=''
        style={{ backgroundColor: "black", padding: "100px 0px" }}
      >
        {/* <TestimonialSlider /> */}
        <Testimonials />
      </div>
      <div
        className=''
        style={{ backgroundColor: "#2b2929", padding: "100px 0px" }}
      >
        <AccordianFAQ />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ backdropFilter: "blur(8px)" }}
      >
        <div className='enrollModule'>
          <EnrollModal handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  );
}
