import Slider from "react-slick";
import { Icon } from "@iconify/react";
import mentorsData from "./mentorsData";
export default function ViewMentors() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings} className='rdSlides'>
      {mentorsData.map((data, index) => (
        <div className='viewMentors'>
          <div
            className='mentorImg cs-shine_hover_1'
            style={{ backgroundImage: `url(${data.imgSrc})` }}
          ></div>
          <div className='mentorName'>
            <h5>{data.name}</h5>
            <h6>{data.position}</h6>
            <h5 className='mentCompany'>{data.company}</h5>
          </div>
        </div>
      ))}
    </Slider>
  );
}
