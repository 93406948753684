import "./details.css";
import { Icon } from "@iconify/react";
import CareerChoices from "./CareerChoices";
import Mentors from "./Mentors";
import SkillDev from "./SkillDev";
import Stats from "./Stats";
export default function Details({ setOpen }) {
  return (
    <div className='detailsContainer'>
      <div className='dTitle'>
        <h5 className='dText'>Building </h5>
        <span className='dSpan'> Sucessful Career</span>
        <br />
        <h5 className='dText'>Made Easy with</h5>{" "}
        <span className='dSpan'>Encoding Careers</span>
      </div>
      <div className='detailsMain'>
        <div className='detailsLeft'>
          <div className='stickyLeft'>
            <div className='leftDetailsContainer'>
              <div className='dLeftCard'>
                <h6>
                  Encoding career is a place to clarify your career, gain
                  real-world skills and get 1-on-1 mentorship to excel in your
                  chosen field.
                </h6>
                <div className='dLeftDetails'>
                  <h4 className='text1'>Achieve Career Success</h4>
                  <Icon
                    icon='solar:map-arrow-up-line-duotone'
                    className='dIcon1'
                  />
                  <h4 className='text2'>
                    Find a right job with proper guidance
                  </h4>
                  <Icon
                    icon='solar:map-arrow-up-line-duotone'
                    className='dIcon2'
                  />
                  <h4 className='text3'>Build Industry-level Skils</h4>
                  <Icon
                    icon='solar:map-arrow-up-line-duotone'
                    className='dIcon3'
                  />
                  <h4 className='text4'>Learn about Finance industry</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='detailsRight'>
          <div className='stickyRight'>
            <CareerChoices setOpen={setOpen} />
          </div>

          <div className='stickyRight'>
            <SkillDev />
          </div>
          <div className='stickyRight2'>
            <Mentors />
          </div>
        </div>
      </div>
    </div>
  );
}
