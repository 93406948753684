import { Icon } from "@iconify/react";
import parse from "html-react-parser";

export default function BonusCard({ data }) {
  return (
    <div className={"bonusDiv " + data.class}>
      <div className='bonusChip'>
        <h6>{parse(data.module)}</h6>
      </div>

      <h4>{data.title}</h4>
      <p>
        <li style={{ marginLeft: "15px" }}>{data.description}</li>
      </p>
      {data.points.map((point, index) => (
        <div className='bonusDetail' key={index}>
          <Icon icon='line-md:star-pulsating-loop' className='bonusIcon' />{" "}
          <h6>{point}</h6>
        </div>
      ))}
    </div>
  );
}
