import { Icon } from "@iconify/react";
import GiftIcon from "./GiftIcon";
import EnrollButton from "../Elements/EnrollButton";
import "./ro.css";
export default function RoadmapOffer({ setOpen }) {
  return (
    <div className='gCardContainer'>
      {" "}
      {/* <div
        className='button glass'
        style={{ margin: "20px" }}
        //   onClick={() => {
        //     handleClick("name");
        //   }}
      >
        <h3>Explore Roadmaps</h3>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div> */}
      <div className='gCard'>
        <h5 style={{ margin: "15px" }}>What Does Roadmap Offer</h5>
        <div className='gParent'>
          <div className='div1 gDiv'>
            <Icon icon='iconamoon:folder-video-fill' className='gIcon' />
            <h6>7 Video Modules</h6>
          </div>
          <div className='div2 gDiv' style={{ marginRight: "15px" }}>
            <Icon
              icon='game-icons:puzzle'
              className='gIcon'
              style={{ marginLeft: "15px" }}
            />
            <h6>Practical Assignment</h6>
          </div>
          <div className='div3 gDiv' style={{ marginRight: "15px" }}>
            <GiftIcon
              className='gIcon'
              style={{ scale: "4", marginRight: "25px" }}
            />
            <h5>Free Bonus worth ₹19,996 </h5>
          </div>
          <div className='div4 gDiv'>
            <Icon icon='akar-icons:people-group' className='gIcon' />
            <h6>Active Community</h6>
          </div>
          <div className='div5 gDiv' style={{ marginRight: "15px" }}>
            <Icon icon='tabler:certificate' className='gIcon' />
            <h6>Certification</h6>
          </div>
        </div>
      </div>
      <EnrollButton variant={"full"} setOpen={setOpen} />
    </div>
  );
}
