import "./cc.css";
import RoadmapScroll from "./RoadmapScroll";
import { Icon } from "@iconify/react";
export default function CareerChoices({ setOpen }) {
  return (
    <div className='ccContainer'>
      <div className='ccTitle'>
        <img src='images/small_elements/1.png' alt='' />
        <h4>Best Career Choices</h4>
      </div>
      <div className='ccArrowDiv'>
        <h1 className='ccH1'> 5 </h1>
        <h5 className='ccH5'>Finance Roadmaps</h5>
      </div>
      <RoadmapScroll />
      <div
        className='button glass shine'
        style={{
          marginTop: "15px",
          width: "120px",
          height: "18px",
          margin: "auto",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <h6>Enroll Now</h6>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div>
    </div>
  );
}
