import ProblemCard from "./ProblemCard";
import Slider from "react-slick";
import "./ak.css";
const problemData = [
  {
    title: "PROBLEM #1",
    iconLink: "ion:telescope-outline",
    problem:
      "70% graduates lack a long term vision of the profession want to pursue",
  },
  {
    title: "PROBLEM #2",
    iconLink: "mingcute:refresh-4-fill",
    problem:
      "60% professional in early years switch their job due to lack of role clarity",
  },
  {
    title: "PROBLEM #3",
    iconLink: "line-md:alert-square-loop",
    problem:
      "Traditional career counseling provides limited, one-time guidance",
  },
  {
    title: "PROBLEM #4",
    iconLink: "game-icons:misdirection",
    problem:
      "Lack of structured roadmaps on how to gain required industry - level skills ",
  },
  {
    title: "PROBLEM #5",
    iconLink: "fluent:target-arrow-16-regular",
    problem:
      "Without proper guidance, individuals struggle to achieve their career success",
  },
  {
    title: "PROBLEM #6",
    iconLink: "line-md:compass-loop",
    problem: "Lack of direction often leads to lack of passion at work",
  },
];
export default function AboutKaran() {
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    // speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    // speed: 20000,
    slidesToShow: 4,
    slidesToScroll: 2,

    arrows: false,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: 2,
      //     // dots: true,
      //     arrows: false,
      //   },
      // },
    ],
  };
  return (
    <div className='akContainer'>
      <div className='akDiv'>
        <h5>How it all Started?</h5>
        <div className='aboutDiv'>
          <img src='/images/karan.png' alt='Karan' />
          <h6>Hi! My Name is Karan Damania</h6>
          <p>For me, building a career has been a priority.</p>
          <p>
            And in doing so I have been very successful as in 2.5 years of my
            career is was able to 5x my salary. (From earning ₹10,000 as Intern
            in start to making ₹50,000 as entry level analyst at an Investment
            Bank.)
          </p>
          <p>
            All this was possible due to structured guidance from Industry
            experts and skill building. But then...
          </p>
          <div>
            <Slider {...settings}>
              {problemData.map((prob, index) => (
                <div key={index}>
                  <ProblemCard prob={prob} />
                </div>
              ))}
            </Slider>
          </div>

          <p>
            I came across this problems and decided to start the journey of
            Encoding Careers.
          </p>
          <p>
            This roadmaps are my way of giving back, sharing the insights &
            strategies from Industry experts would make impact.
          </p>
        </div>
      </div>
    </div>
  );
}
