import Accordion from "./Accordion";

const accordionDataLeft = [
  {
    question: "What is the Encoding Careers Roadmap?",
    answer:
      "The Encoding Careers Roadmap is a comprehensive, personalized career guidance platform that helps individuals discover their passion, build relevant skills, and achieve success in their desired professions.",
  },
  {
    question: "Who is the roadmap designed for?",
    answer:
      "The roadmap is designed for students, recent graduates, career changers, and professionals seeking upskilling or reskilling in their current or desired field.",
  },
  {
    question: "How does the roadmap work?",
    answer:
      "It starts with a passion-to-career matching quiz, followed by personalized career paths, interactive learning modules, hands-on projects, access to expert mentors, and a like-minded community.",
  },
  {
    question: "How long does it take to complete a roadmap?",
    answer:
      "The duration of a roadmap varies, but it typically takes 3-4 months to complete, with the flexibility to progress at your own pace.",
  },
  {
    question: "Can I access the roadmap from any device?",
    answer:
      "Yes, the roadmap can be accessed from any device, including desktops, laptops, tablets, and smartphones, for a seamless learning experience.",
  },
  {
    question: "What resources and learning materials are included?",
    answer:
      "The roadmap includes video modules, interactive projects, curated resource libraries (free and paid), and access to industry expert mentors for personalized guidance.",
  },
];

const accordionDataRight = [
  {
    question: "How does mentorship work within the roadmap?",
    answer:
      "Mentorship involves regular 1:1 sessions with industry professionals, who provide career advice, guidance, and support throughout your learning journey.",
  },
  {
    question: "Is there a community aspect to the roadmap?",
    answer:
      "Yes, the roadmap includes a vibrant community of like-minded individuals, fostering peer support, collaboration, and networking opportunities.",
  },
  {
    question: "What is the pricing structure for the roadmap?",
    answer:
      "Pricing options include a one-time fee or subscription model, with discounts available for students and early adopters.",
  },
  {
    question: "Is there a free trial or demo available?",
    answer:
      "A free trial or demo of the 1st video module of roadmap product is available for a limited period to explore the features before purchasing.",
  },
  {
    question: "What is your refund or cancellation policy?",
    answer:
      "The roadmap does not offer refund or cancellations once the purchase is made.",
  },
  {
    question: "Will there be any certificate on Completion?",
    answer: "Yes! You will receive a certificate on completion.",
  },
];
export default function AccordianFAQ() {
  return (
    <div className='AccContianer'>
      <h3>Frequently Asked Questions:</h3>
      <div className='AccContent'>
        <Accordion accordionData={accordionDataLeft} />
        <Accordion accordionData={accordionDataRight} />
      </div>
    </div>
  );
}
