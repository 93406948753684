import { Icon } from "@iconify/react";
import { useState } from "react";
import "./pq.css";
export default function ProgramQuestions({ setOpen }) {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const handleOptionClick = (index) => {
    setSelectedAnswers((prev) =>
      prev.includes(index)
        ? prev.filter((id) => id !== index)
        : [...prev, index]
    );
  };
  const questions = [
    {
      title: "Ambitious and driven",
      description:
        "I need structured roadmap and resources to fuel my ambition and career success in my chosen career path.",
    },

    {
      title: "High paying finance industry jobs",

      description:
        "I want Industry-specific courses, mentorship, and job placement for lucrative, high paying finance roles.",
    },
    {
      title: "Structured guidance and mentorship",

      description:
        "I want Personalized guidance from experienced mentors to accelerate my career growth.",
    },
    {
      title: "Future-proof skills",

      description:
        "I want continuously updated resources to stay ahead of the curve and develop skills that future-proof my career.",
    },
    {
      title: "Projects and Portfolio building",

      description:
        "I want Hands-on projects, case studies, and portfolio building opportunities.",
    },
    // {
    //   title: "Want to develop practical, industry-relevant skills",

    //   description:
    //     "Courses designed by experts for practical, industry-relevant skills.",
    // },
    {
      title: "Master interview techniques",

      description:
        "I want comprehensive guidance on job search, resumes, and interview preparation.",
    },
    // Want to master effective job search and interview techniques
  ];
  return (
    <div
      className='pqContainer'
      // style={{
      //   textAlign: "center",
      //   // maxWidth: "600px",
      //   margin: "auto",
      //   paddingTop: "30px",
      // }}
    >
      <h3>Is this program for You?</h3>
      <div className='pqGrid'>
        {questions.map((question, index) => (
          <div
            key={index}
            className={
              "ProgramQuestions " +
              (selectedAnswers.length > 0 && selectedAnswers.includes(index)
                ? "selectedDiv"
                : "")
            }
            onClick={() => handleOptionClick(index)}
          >
            <Icon
              icon='line-md:square-to-confirm-square-transition'
              className={
                "QuestionsIcon " +
                (selectedAnswers.length > 0 && selectedAnswers.includes(index)
                  ? "selectedIcon"
                  : "")
              }
            />
            <div className='pqQuestion'>
              <h5> {question.title}</h5>
              <h6> {question.description}</h6>
            </div>
          </div>
        ))}
      </div>
      <div style={{ width: "300px", margin: "auto", margin: "20px 0px" }}>
        <div
          className='button glass rdButton shine'
          style={{ margin: "0px 20px" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <h6>Enroll Now</h6>
          <Icon
            icon='bi:arrow-right'
            style={{ scale: "2", marginLeft: "15px", color: "white" }}
          />
        </div>{" "}
      </div>
    </div>
  );
}
