import Slider from "react-slick";
import TestimonialBase from "./TestimonialBase";
export default function Testimonials() {
  const testimonialData = [
    {
      testimonialThumb: "/images/testimonials_users/1.png",
      testimonialText:
        "I used to feel confused about my future career path after college. Encoding Careers' roadmap gave me a clear long-term vision by explaining the different roles and growth opportunities in my field of interest.",
      avatarName: "Rahul M.",
      avatarDesignation: "Delhi",
      ratings: "4",
    },
    {
      testimonialThumb: "/images/testimonials_users/3.png",
      testimonialText:
        "As a student, I lacked direction on which professional course to pursue. The career roadmap showed me the complete journey from education to getting a job and advancing my career.",
      avatarName: "Sneha R.",
      avatarDesignation: "Pune",
      ratings: "5",
    },
    {
      testimonialThumb: "/images/testimonials_users/2.png",
      testimonialText:
        "I didn't have a plan for where I wanted to be in 5-10 years. The roadmap helped me understand my interests and map out goals to achieve professional success over the long-term.",
      avatarName: "Arjun K.",
      avatarDesignation: "Bengaluru",
      ratings: "4.5",
    },
    {
      testimonialThumb: "/images/testimonials_users/4.png",
      testimonialText:
        "In my first job, I was confused about the skills I needed and how my role fit into the bigger picture. The roadmap clearly explained my role's responsibilities and the path to progress.",
      avatarName: "Neha S.",
      avatarDesignation: "Mumbai",
      ratings: "3.5",
    },
    {
      testimonialThumb: "/images/testimonials_users/5.png",
      testimonialText:
        "After 2 years at my job, I considered switching as I didn't see career growth. The roadmap showed me the advancement opportunities in this field if I developed the right skills.",
      avatarName: " Anand D.",
      avatarDesignation: "Chennai",
      ratings: "4",
    },
    {
      testimonialThumb: "/images/testimonials_users/7.png",
      testimonialText:
        "As a fresher, I joined a company without understanding what I was getting into. The roadmap gave me clarity on my role, day-to-day work, and future prospects in this profession.",
      avatarName: " Priyanka V.",
      avatarDesignation: "Hyderabad",
      ratings: "4",
    },
    {
      testimonialThumb: "/images/testimonials_users/6.png",
      testimonialText:
        "Traditional career counselors just told me to choose between 2-3 options based on my interests. The roadmap provided in-depth guidance for my chosen field from experts.",
      avatarName: "Rohan T.",
      avatarDesignation: "Kolkata",
      ratings: "5",
    },
    {
      testimonialThumb: "/images/testimonials_users/8.png",
      testimonialText:
        "My college's career counseling was very generic. Encoding Careers' roadmap offered personalized, comprehensive paths in multiple professions based on my profile.",
      avatarName: "Ishaan M.",
      avatarDesignation: "Ahmedabad",
      ratings: "3.5",
    },
    {
      testimonialThumb: "/images/testimonials_users/9.png",
      testimonialText:
        "Career counselors advised me based on a single discussion. The roadmap acts as an ongoing guide, helping me at every step of my professional journey.",
      avatarName: " Deepika R.",
      avatarDesignation: "Jaipur",
      ratings: "4",
    },
    {
      testimonialThumb: "/images/testimonials_users/10.png",
      testimonialText:
        "I knew I wanted to work in finance, but didn't know how to acquire the required skills. The roadmap mapped out the technical and soft skills I need through courses and projects.",
      avatarName: "Aditya G.",
      avatarDesignation: "Chandigarh",
      ratings: "3.5",
    },
    {
      testimonialThumb: "/images/testimonials_users/11.png",
      testimonialText:
        "As a fresher, I lacked practical experience and skills valued by employers. The roadmap provided structured guidance on building an industry-ready skillset.",
      avatarName: "Trisha M.",
      avatarDesignation: "Kochi",
      ratings: "4.5",
    },
    {
      testimonialThumb: "/images/testimonials_users/17.png",
      testimonialText:
        "I didn't have any mentors to guide me, so my career growth was slow. The roadmap gave me access to experienced mentors and kept me on track towards my goals.",
      avatarName: "Ankita S.",
      avatarDesignation: "Lucknow",
      ratings: "5",
    },
    {
      testimonialThumb: "/images/testimonials_users/13.png",
      testimonialText:
        "Without proper guidance, I made some wrong career choices early on. The roadmap has been like a mentor, showing me the right path to success based on my interests.",
      avatarName: " Sanjay P.",
      avatarDesignation: "Guwahati",
      ratings: "4",
    },
    // {
    //   testimonialThumb: "/images/testimonials_users/14.png",
    //   testimonialText:
    //     "As a professional, I often felt lost and unsure of what to do next. The roadmap provided the much-needed direction and mentorship to achieve career success.",
    //   avatarName: "Meena D.",
    //   avatarDesignation: "Coimbatore",
    //   ratings: "4.5",
    // },
    // {
    //   testimonialThumb: "/images/testimonials_users/15.png",
    //   testimonialText:
    //     "In my first job, I was confused about the skills I needed and how my role fit into the bigger picture. The roadmap clearly explained my role's responsibilities and the path to progress.",
    //   avatarName: "Neha S.",
    //   avatarDesignation: "Mumbai",
    //   ratings: "3.5",
    // },
    {
      testimonialThumb: "/images/testimonials_users/16.png",
      testimonialText:
        "I chose my profession based on family expectations, not personal passion, and felt demotivated. The roadmap helped me rediscover my interests and find work I enjoy.",
      avatarName: "Arun K.",
      avatarDesignation: "Vizag",
      ratings: "5",
    },
    // {
    //   testimonialThumb: "/images/testimonials_users/17.png",
    //   testimonialText:
    //     "My job felt like a chore. The roadmap made me realize my strengths and showed me passionate career options that are a better fit for me.",
    //   avatarName: "Pooja B.",
    //   avatarDesignation: "Nagpur",
    //   ratings: "4",
    // },
    {
      testimonialThumb: "/images/testimonials_users/18.png",
      testimonialText:
        "I used to just go to work but lacked any excitement or drive. Following my personalized roadmap and getting guidance reignited my passion for my profession.",
      avatarName: "Rakesh M.",
      avatarDesignation: "Bhubaneswar",
      ratings: "4.5",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: "900px",
        margin: "auto",
        paddingTop: "30px",
        // paddingBottom: "30px",
      }}
    >
      <h3 className='testH3'>Hear it from our learners</h3>
      <Slider {...settings} className=''>
        {testimonialData.map((data, index) => (
          <TestimonialBase key={index} data={data} />
        ))}
      </Slider>
    </div>
  );
}
